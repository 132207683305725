import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ['header', 'description', 'ctaLink', 'titleInput', 'descriptionInput', 'ctaUrlInput', 'ctaTextInput']

  handleInputChange(event) {
    const { fieldTarget } = event.params

    switch (fieldTarget) {
      case 'title':
        this.updateHeader()
        break
      case 'description':
        this.updateDescription()
        break
      case 'cta_url':
        this.updateLinkUrl()
        break
      case 'cta_text':
        this.updateLinkText()
        break
      default:
        break
    }
  }

  updateHeader() {
    this.headerTarget.innerText = this.titleInputTarget.value
  }

  updateDescription() {
    this.descriptionTarget.innerText = this.descriptionInputTarget.value
  }

  updateLinkUrl() {
    this.ctaLinkTarget.href = this.ctaUrlInputTarget.value
  }

  updateLinkText() {
    this.ctaLinkTarget.innerText = this.ctaTextInputTarget.value
  }
}
